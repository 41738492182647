.has {
  &-text-overflow-ellipsis {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-text-nowrap {
    white-space: nowrap;
  }

  &-content-overflow-ellipsis {
    max-height: 73px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;

    background: -webkit-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 1) 40%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.breadcrumb li {
  &.is-active > span {
    padding: 0 0.75em;
  }

  &:first-child > span {
    padding-left: 0;
  }
}

.section {
  animation: fadein 0.5s;
}

.select.is-block select {
  width: 100%;
}

.notification {
  &.is-light {
    &.is-primary {
      background-color: #ebfffc;
      color: #00947e;
    }

    &.is-danger {
      background-color: #feecf0;
      color: #cc0f35;
    }
  }
}

.is-family-code {
  font-family: 'Roboto Mono', sans-serif !important;
  font-size: 0.9rem;
  line-height: 1.5em;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.level.level-multiline {
  flex-wrap: wrap;
  .level-item.is-one-third {
    flex-basis: 26%;
  }
  .level-item.is-one-third:nth-child(n + 4) {
    margin-top: 10px;
  }
}

.is-size-8 {
  font-size: $size-8;
}

.tag:not(body) {
  font-size: 0.75rem;
  height: 1.75em;
  line-height: 1.75;
}
