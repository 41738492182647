// Typography
$size-7: 0.875rem;
$size-8: 0.75rem;
$body-line-height: 1.34;
$body-color: hsl(0, 100%, 0%);

// Section
$section-padding-desktop: 0 0 0.5rem;
$section-padding: 1rem 1.5rem 3rem;

// Tag
$tag-radius: 16px;
